@font-face {
font-family: '__maisonNew_5121ae';
src: url(/_next/static/media/c3676e4a21a9ab5e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__maisonNew_Fallback_5121ae';src: local("Arial");ascent-override: 83.82%;descent-override: 28.39%;line-gap-override: 0.00%;size-adjust: 118.35%
}.__className_5121ae {font-family: '__maisonNew_5121ae', '__maisonNew_Fallback_5121ae';font-weight: 600;font-style: normal
}.__variable_5121ae {--font-maison-neue: '__maisonNew_5121ae', '__maisonNew_Fallback_5121ae'
}

@font-face {
font-family: '__acuminPro_9f737f';
src: url(/_next/static/media/03f40c1d191ea707-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__acuminPro_Fallback_9f737f';src: local("Arial");ascent-override: 74.29%;descent-override: 26.78%;line-gap-override: 20.21%;size-adjust: 98.94%
}.__className_9f737f {font-family: '__acuminPro_9f737f', '__acuminPro_Fallback_9f737f';font-weight: 400;font-style: normal
}.__variable_9f737f {--font-acumin-pro: '__acuminPro_9f737f', '__acuminPro_Fallback_9f737f'
}

@font-face {
font-family: '__domainDisplay_b68074';
src: url(/_next/static/media/7d25122a695a7f84-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}@font-face {font-family: '__domainDisplay_Fallback_b68074';src: local("Arial");ascent-override: 97.70%;descent-override: 26.56%;line-gap-override: 0.00%;size-adjust: 105.43%
}.__className_b68074 {font-family: '__domainDisplay_b68074', '__domainDisplay_Fallback_b68074';font-weight: 600
}.__variable_b68074 {--font-domain-display: '__domainDisplay_b68074', '__domainDisplay_Fallback_b68074'
}

